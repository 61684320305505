import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const sessionData = sessionStorage.getItem('workspaceData');
const workspaceData = sessionData ? JSON.parse(sessionData) : ''; 
 
const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: {
    companyName:workspaceData?.companyName|| '',
    workspaceHandle:workspaceData?.workspacename|| '',
    companySize:workspaceData?.companySize||'',
    role:workspaceData?.role||''
  },
  reducers: {
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    setWorkspaceHandle: (state, action) => {
      state.workspaceHandle = action.payload;
    },
    setCompanysize: (state, action) => {
        state.companySize = action.payload;
      },
    setRole:(state, action) => {
        state.role = action.payload;
      },
  },
});

export const { setCompanyName, setWorkspaceHandle, setRole,setCompanysize } = workspaceSlice.actions;
export default workspaceSlice.reducer;
