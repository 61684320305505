import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkObject } from '@src/dbSyncManager/tables/workObjects.entity';

interface WorkObjectsState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: WorkObjectsState = {
  mapData: new Map(),
  arrayData: [],
};

const workObjectsSlice = createSlice({
  name: 'workObjects',
  initialState,
  reducers: {
    setWorkObjects(state, action: PayloadAction<WorkObject[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setWorkObjects } = workObjectsSlice.actions;
export default workObjectsSlice.reducer;
