import Moment from 'moment';
export const formatDate = (date: string) => {
  try {
    const format = 'DD MMM YYYY';
    Moment.locale('en');
    if (date != null) return Moment(date).format(format);
    return '';
  } catch (e) {
    return '-';
  }
};

export const capitalizeFirstLetter = (data: string) => {
  if (typeof data !== 'string' || data.length === 0) {
    return ''; // Return empty string if the input is invalid
  }

  // Capitalize the first letter and convert the rest to lowercase
  return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
};

export const formatDateTime = (timestamp: string | any) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Use 24-hour format
  };
  return new Date(timestamp).toLocaleString(undefined, options);
};

export const getTimeAgo = (timestamp: string): string => {
  const now = new Date();
  const diff = now.getTime() - new Date(timestamp).getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (days < 1) {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return new Date(timestamp).toLocaleString(undefined, options);
  } else if (days <= 7) {
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (weeks <= 4) {
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else if (months <= 12) {
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
};

export const formatReadable = (text: any) => {
  // Add spaces before capital letters (handling camelCase and PascalCase)
  let formattedText = text?.replace(/([a-z])([A-Z])/g, '$1 $2');
  // Replace snake_case and kebab-case with spaces
  formattedText = formattedText.replace(/[_-]/g, ' ');
  // Add spaces before sequences of capital letters followed by lowercase letters (handling cases like "Myaccount")
  formattedText = formattedText.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  // Capitalize the first letter of each word
  formattedText = formattedText.replace(/\b\w/g, (char: any) => char.toUpperCase());

  return formattedText;
};

export const flattenArray = (nestedArray: any) => {
  return nestedArray.reduce((acc: any, currentArray: any) => acc.concat(currentArray), []);
};

export const sortByTimeDescending = (array: any) => {
  return array.sort((a: any, b: any) => {
    const timeA = new Date(a.time).getTime();
    const timeB = new Date(b.time).getTime();
    return timeB - timeA; // Descending order
  });
};

export function checkTokenExpiry() {
  // Get the stored appState from localStorage
  const storedState: any = localStorage.getItem('appState');

  // Parse the JSON string to an object
  const parsedState = JSON.parse(storedState);

  // Access the accessToken from the parsed object
  const token = parsedState?.auth?.accessToken;
  if (token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1])); // Assuming the token is in JWT format
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    if (currentTime > expirationTime) {
      // Token expired, perform cleanup and redirect
      clearLocalStorageAndIndexDB();
      redirectToLoginPage();
      return false;
    }
    return true;
  } else {
    // Token not found, redirect to login page
    redirectToLoginPage();
    return false;
  }
}

export function clearLocalStorageAndIndexDB() {
  // Clear token from local storage
  localStorage.removeItem('appState');
  localStorage.removeItem('SyncStatus');
  // const storedAppStateString = localStorage.getItem('appState');
  // let storedAppState: any = null;
  // if (storedAppStateString !== null) {
  //   storedAppState = JSON.parse(storedAppStateString);
  // }

  // if (storedAppState && storedAppState.auth && storedAppState.auth.accessToken) {
  //   storedAppState.auth.accessToken = '';
  //   storedAppState.auth.loggedIn = false;
  //   localStorage.setItem('appState', JSON.stringify(storedAppState));
  // }

  // Clear IndexedDB data
  var request = indexedDB.deleteDatabase('flute');
  request.onsuccess = function () {
    console.log('IndexedDB cleared');
  };
}

export function redirectToLoginPage() {
  window.location.href = '/login'; // Redirect to your login page
}

import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../dbSyncManager/init'; // Import your database setup

export const getDisplayName = (type: string, id?: any) => {
  const authUserString: any = window.localStorage?.getItem('appState') || '';
  const accessToken: any = JSON.parse(authUserString || '{}')?.auth?.accessToken || '';
  const loggedInUserId: any = JSON.parse(authUserString || '{}')?.auth?.userId || '';
  const userId = id || loggedInUserId;
  return useLiveQuery(async () => {
    const userList = await db.users.toArray();
    const user = userList.find((user: any) => user.id === userId);
    if (!user) return ''; // Return an empty string if user is not found

    const { firstName, lastName, preferredName } = user;

    // Function to filter out null/undefined values and join the remaining parts
    const joinNameParts = (parts: (string | undefined)[]) => {
      return parts.filter((part) => part).join(' ');
    };

    if (type === 'FirstName LastName (Preferred Name)') {
      return joinNameParts([firstName, lastName, preferredName ? `(${preferredName})` : undefined]);
    }
    if (type === 'LastName FirstName (Preferred Name)') {
      return joinNameParts([lastName, firstName, preferredName ? `(${preferredName})` : undefined]);
    }

    return ''; // Default return if no matching type
  }, [type, userId]);
};

export const parseFunctions = (key: any, value: any) => {
  if (typeof value === 'string' && (value.includes('params') || value.includes('function'))) {
    // Parse function strings
    const functionTemplate = `(${value})`;
    try {
      // Use eval to create a function
      return eval(functionTemplate);
    } catch (error) {
      // If eval fails, return the original string
      return value;
    }
  } else if (typeof value === 'object') {
    // If the value is an object, recursively parse its properties
    for (const prop in value) {
      value[prop] = parseFunctions(prop, value[prop]);
    }
  }
  return value;
};

export const hexToRGBA = (hex: string, opacity: number = 0.12): string => {
  // Remove the leading '#' if present
  hex = hex?.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex?.substring(0, 2), 16);
  let g = parseInt(hex?.substring(2, 4), 16);
  let b = parseInt(hex?.substring(4, 6), 16);

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const hexWithOpacity = (hex: string | undefined, opacity: number = 0.12): string => {
  // Ensure hex is defined and a valid string
  if (!hex) {
    // console.error("Invalid hex color provided to hexWithOpacity:", hex);
    return "#000000";
  }

  // Remove the leading '#' if present
  hex = hex.replace(/^#/, '');

  // Convert opacity to a 2-digit hex value (0 to 255 range)
  const alpha = Math.round(opacity * 255).toString(16).padStart(2, '0');

  // Return the hex color with the alpha channel added
  return `#${hex}${alpha}`;
};

export const removeTrailingColon = (str: string) => (str.endsWith(':') ? str.slice(0, -1) : str);


export const formatDateToMonthDay = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  return `${month} ${day}`;
};

export function getRelativeTime(timestamp: Date): string {
  const now = new Date();
  const createdTime = new Date(timestamp);
  const diffInMs = now.getTime() - createdTime.getTime();

  const msPerMinute = 1000 * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const msPerMonth = msPerDay * 30; // Approximate month
  const msPerYear = msPerDay * 365; // Approximate year

  // Calculate the differences
  const minutesAgo = Math.floor(diffInMs / msPerMinute);
  const hoursAgo = Math.floor(diffInMs / msPerHour);
  const daysAgo = Math.floor(diffInMs / msPerDay);
  const weeksAgo = Math.floor(diffInMs / msPerWeek);
  const monthsAgo = Math.floor(diffInMs / msPerMonth);
  const yearsAgo = Math.floor(diffInMs / msPerYear);

  // Return the appropriate message
  // if (minutesAgo < 60) {
  //   return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
  // } else if (hoursAgo < 24) {
  //   return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
  // } else if (daysAgo < 7) {
  //   return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
  // } else if (weeksAgo < 4) {
  //   return `${weeksAgo} week${weeksAgo === 1 ? '' : 's'} ago`;
  // } else if (monthsAgo < 12) {
  //   return `${monthsAgo} month${monthsAgo === 1 ? '' : 's'} ago`;
  // } else {
  //   return `${yearsAgo} year${yearsAgo === 1 ? '' : 's'} ago`;
  // }
  if (minutesAgo < 60) {
    return `${minutesAgo} min`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo} hr`;
  } else if (daysAgo < 7) {
    return `${daysAgo} d`;
  } else if (weeksAgo < 4) {
    return `${weeksAgo} w`;
  } else if (monthsAgo < 12) {
    return `${monthsAgo} m`;
  } else {
    return `${yearsAgo} y`;
  }
}

export const formatTimestamp = (timestamp: string | Date): string => {
  const date = typeof timestamp === 'string' ? new Date(timestamp) : timestamp;
  const now = new Date();

  // Days of the week and months to display
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Day of the week and month date
  const dayOfWeek = daysOfWeek[date.getDay()]; // e.g., Monday
  const day = date.getDate(); // e.g., 28
  const month = months[date.getMonth()]; // e.g., Oct
  const hours = date.getHours().toString().padStart(2, '0'); // e.g., 10
  const minutes = date.getMinutes().toString().padStart(2, '0'); // e.g., 56

  // Time difference in milliseconds
  const timeDifference = now.getTime() - date.getTime();

  // Calculate difference in days, hours, and minutes
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursAgo = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesAgo = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  // Format the relative time
  let timeAgo = '';
  if (daysAgo > 0) {
    timeAgo = `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  } else if (hoursAgo > 0) {
    timeAgo = `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else if (minutesAgo > 0) {
    timeAgo = `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
  } else {
    timeAgo = 'just now';
  }

  // Return formatted string
  return `${dayOfWeek}, ${day} ${month}, ${hours}:${minutes} (${timeAgo})`;
};


export const formatTimestampShort = (inputDate: string | Date): string => {
  const date = new Date(inputDate);
  const now = new Date();

  // Calculate time differences in milliseconds
  const timeDiff = now.getTime() - date.getTime();
  const oneDay = 24 * 60 * 60 * 1000;

  // Helper to format hours and minutes (e.g., 11:49 AM)
  const formatTime = (d: Date) => {
    const hours = d.getHours() % 12 || 12;
    const minutes = d.getMinutes().toString().padStart(2, "0");
    const amPm = d.getHours() >= 12 ? "PM" : "AM";
    return `${hours}:${minutes} ${amPm}`;
  };

  // Helper to format date within the same year (e.g., 8 Nov)
  const formatDayMonth = (d: Date) =>
    `${d.getDate()} ${d.toLocaleString("en-US", { month: "short" })}`;

  // Helper to format date with year (e.g., 23 Oct 2023)
  const formatDayMonthYear = (d: Date) =>
    `${d.getDate()} ${d.toLocaleString("en-US", { month: "short" })} ${d.getFullYear()}`;

  // Check conditions
  if (timeDiff < oneDay) {
    return formatTime(date); // Less than 24 hours ago
  } else if (date.getFullYear() === now.getFullYear()) {
    return formatDayMonth(date); // Within the current year
  } else {
    return formatDayMonthYear(date); // In a previous year
  }
};

export const formatDateForInput = (date: Date): string => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new TypeError('Invalid date object provided');
  }
  const pad = (num: number) => num.toString().padStart(2, '0');
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const getFormattedDate = (date: Date): string => {
  const isoString = date.toISOString(); // Get the ISO string (e.g., "2024-12-21T19:20:11.420Z")
  
  // Extract the "Z" (UTC) part and replace it with the time zone offset
  const timeZoneOffset = date.getTimezoneOffset();
  const hoursOffset = Math.floor(Math.abs(timeZoneOffset) / 60);
  const minutesOffset = Math.abs(timeZoneOffset) % 60;
  const sign = timeZoneOffset > 0 ? "-" : "+";
  
  const formattedOffset = `${sign}${String(hoursOffset).padStart(2, '0')}:${String(minutesOffset).padStart(2, '0')}`;

  return isoString.replace("Z", formattedOffset); // Replace 'Z' with the offset
};

// Utility function to convert date to UTC
export const toUTC = (date: string): string | null => {
  if (!date) return null;
  const parsedDate = new Date(date);
  return isNaN(parsedDate.getTime()) ? null : parsedDate.toISOString();
};