import { AppState } from '@src/app/rootReducer';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText, { TextVariant } from '@src/components/base/fltText/fltText';
import { ProfileMenu } from '@src/components/composite/profileMenu/profileMenu';
import { toggelSideBar } from '@src/features/sideBar/sideBarSlice';
import { toggleColorMode } from '@src/features/theme/themeSlice';
import { Theme } from '@src/styles/themes/themeInterface';
import { formatReadable } from '@src/utils/common';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NotificationMenu } from '../notificationMenu/notificationMenu';
import { transformPath } from './functions';
import { HeaderProps } from './headerInterface';
import { HeaderWrapper } from './headerStyles';

export const Header = ({ baseRoute, activeItem }: HeaderProps) => {
  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const { id, initiativeid } = useParams();
  const theme: Theme = useSelector((data: AppState) => data.theme.theme);
  const themeMode = useSelector((data: AppState) => data.theme);
  const isSideCollapse = useSelector((state: AppState) => state.sideBar.collapsed);
  const groups = useSelector((data: AppState) => data.groups.arrayData);
  const documents = useSelector((data: AppState) => data.documents.arrayData);
  const navigate = useNavigate();
  let type = location.split('/')[2];
  console.log('baseroyte', baseRoute, activeItem);
  const getNameById = (id: any) => {
    return groups?.find((group: any) => group.id == id)?.name;
  };
  const getBillNameById = (id: any) => {
    return documents?.find((group) => group.id == id)?.name;
  };
  const getTeamDetails = (id: any) => {
    return groups?.find((group: any) => group.id == id);
  };
  const name = getNameById(id);
  useEffect(() => {}, [name]);
  const activeItems = activeItem?.split('/');
  const [searchText, setSearchText] = useState('');
  const handleToggleColorMode = () => {
    dispatch(toggleColorMode());
  };
  const [path, setPath] = useState<any>(activeItem);

  const resolvedNames = activeItems?.map((part, index) => {
    if (!isNaN(Number(part))) {
      return getNameById(part); // Always call hooks unconditionally
    }
    return null;
  });

  const updatePath = (activeItem: string) => {
    if (!activeItem) return activeItem;

    const parts = activeItem.split('/');
    const updatedParts = parts.map((part, index) => {
      if (!isNaN(Number(part))) {
        console.log(index, parts[index - 1]);
        if (parts[index - 1] == 'bills' || parts[index - 1] == 'pay') {
          return getBillNameById(part) || part;
        } else return resolvedNames?.[index] || part; // Use precomputed resolved names
      }
      return part;
    });
    return transformPath(updatedParts.join('/'));
  };

  useEffect(() => {
    const pathname = updatePath(activeItem || '');
    if (id) {
      setPath(pathname);
    } else {
      setPath(activeItem);
    }
  }, [activeItem, id]);

  const [variantName, setVariantName] = useState<TextVariant>('title-medium');
  const [searchWidth, setSearchWidth] = useState('250px');
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);
  const handleMouseEnter = (iconName: string) => {
    setHoveredIcon(iconName);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setVariantName('title-medium');
        setSearchWidth('200px');
      } else if (window.innerWidth < 1000) {
        setVariantName('heading-small');
        setSearchWidth('210px');
      } else {
        setVariantName('heading-small');
        setSearchWidth('215px');
      }
    };

    // Set the initial variant based on the current window width
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (theme.themeType === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme.themeType]);
  console.log(getTeamDetails(id), id);
  return (
    <HeaderWrapper theme={theme}>
      <div className="left-container">
        {isSideCollapse && (
          <div
            className="side-bar-toggle-btn-container"
            onMouseEnter={() => handleMouseEnter('SidebarRight')}
            onMouseLeave={handleMouseLeave}
          >
            <FltIcon
              weight="regular"
              name="SidebarSimple"
              size={16}
              onClick={() => {
                dispatch(toggelSideBar());
              }}
            />
          </div>
        )}

        {activeItem && (
          <>
            {path?.split('/')?.map((segment: string, index: number, arr: any) => {
              const isLast = index === arr?.length - 1;
              return (
                <React.Fragment key={index}>
                  {index == 0 && id && (
                    <>
                      <div style={{ marginRight: '4px' }}>
                        <FltIcon
                          name={getTeamDetails(id)?.icon}
                          weight="regular"
                          bg
                          size={12}
                          color={getTeamDetails(id)?.color}
                        />
                      </div>
                      <FltText variant="title-medium" textColor="content-static">
                        {getTeamDetails(id)?.avatarInitials} {'- '}
                      </FltText>
                    </>
                  )}

                  <FltText variant="title-medium" textColor="content-static">
                    {index > 1 && <span className="divider">/</span>}
                  </FltText>
                  <div>
                    <FltText
                      variant={isLast ? 'heading-medium' : 'title-medium'}
                      textColor={isLast ? 'content-primary' : 'content-static'}
                      styles={{
                        cursor: 'pointer',
                      }}
                      onClick={() => navigate(`${baseRoute}`)}
                    >
                      {formatReadable(segment)}
                    </FltText>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>

      <div className="right-container">
        <button className="search-anything-btn">
          <FltIcon name="MagnifyingGlass" weight="regular" iconColor="content-tertiary" size={14} />
          <FltText
            variant="title-small"
            textColor="content-tertiary"
            styles={{ marginRight: '50px' }}
          >
            Search anything...
          </FltText>
          <p style={{ fontSize: '10px', color: theme.content.tertiary }}>
            {'\u2318'} + <span style={{ fontSize: '12px' }}>K</span>
          </p>
        </button>

        <NotificationMenu />
        <ProfileMenu />
      </div>
    </HeaderWrapper>
  );
};
