import { Theme } from './themeInterface';

export const lightTheme: Theme = {
  themeType: 'light',
  color: {
    white: 'var(--absolute-white)',
    black: 'var(--black)',
    grey: 'var(--grey)',
  },
  pages: {
    text: {
      primary: 'var(--grey-90)',
      secondary: 'var(--grey-80)',
      tertiary: 'var(--grey-70)',
      quaternary: 'var(--grey-60)',
      quinary: 'var(--grey-50)',
      // senary: 'var(--primary-50)',
      // septenary: 'var(--absolute-white)',
    },
    background: {
      primary: 'var(--grey-0)',
    },
    border: {
      primary: 'var(--grey-20)',
      secondary: 'var(--grey-20)',
    },
    avatar: { border: { primary: 'var(--light-pages-avatar-border-primary)' } },
    dropShadow: 'var(--light-pages-dropShadow)',
    scrollbar: {
      thumb: 'var(--light-scrollbar-thumb)',
      track: 'var(--light-scrollbar-track)',
      thumbBorder: 'var(--light-thumb-border)',
    },
    layout: {
      sidebar: {
        text: {
          primary: 'var(--grey-90)',
          secondary: 'var(--grey-80)',
          tertiary: 'var(--grey-70)',
          quaternary: 'var(--grey-60)',
          quinary: 'var(--grey-50)',
          senary: 'var(--primary-60)',
          septenary: 'var(--absolute-white)',
          hover: {
            primary: 'var(--primary-60)',
            secondary: 'var(--primary-60)',
          },
        },
        icon: {
          primary: 'var(--grey-60)',
          secondary: 'var(--grey-80)',
          tertiary: 'var(--primary-60)',
          quaternary: 'var(--grey-70)',
        },
        scrollBarColor: 'var(--light-pages-scroll-color)',
        border: {
          primary: 'var(--grey-20)',
          secondary: 'var(--grey-10)',
          tertiary: 'var(--primary-20)',
        },
        background: {
          primary: 'var(--grey-0)',
          hover: {
            primary: 'var(--absolute-white)',
          },
        },
      },
      secondarySidebar: {
        text: {
          primary: 'var(--grey-70)',
          hover: {
            primary: 'var(--primary-60)',
          },
          active: {
            primary: 'var(--primary-70)',
          },
          focus: {
            primary: 'var(--grey-80)',
          },
        },
        background: {
          primary: 'var(--absolute-white)',
          hover: { primary: 'var(--grey-10)' },
          active: { primary: 'var(--grey-20)' },
        },
        boxShadow: {
          primary: `0px 124px 35px 0px rgba(176, 176, 176, 0),
            0px 79px 32px 0px rgba(176, 176, 176, 0.01),
            0px 45px 27px 0px rgba(176, 176, 176, 0.05),
            0px 20px 20px 0px rgba(176, 176, 176, 0.09),
            0px 5px 11px 0px rgba(176, 176, 176, 0.1)`,
        },
        border: {
          primary: 'var(--grey-20)',
        },
      },
      header: {
        background: {
          primary: 'var(--grey-20)',
          secondary: 'var(--absolute-white)',
        },
        text: {
          primary: 'var(--grey-80)',
          secondary: 'var(--grey-60)',
          hover: { secondary: 'var(--grey-90)' },
        },
        border: {
          primary: 'var(--grey-20)',
        },
        icon: {
          primary: 'var(--grey-60)',
          hover: { primary: 'var(--grey-90)' },
        },
      },
    },
  },

  textPrimary: 'var(--light-text-primary)',
  textSecondary: 'var(--light-text-secondary)',
  bg: 'var(--light-bg)',
  borderPrimary: 'var(--light-border-primary)',
  borderSecondary: 'var(--light-border-secondary)',
  button: {
    textPrimary: 'var(--light-button-text-primary)',
    textSecondary: 'var(--light-button-text-secondary)',
    bgPrimary: 'var(--light-button-bg-primary)',
    bgSecondary: 'var(--light-button-bg-secondary)',
    borderPrimary: 'var(--light-button-border-primary)',
    borderSecondary: 'var(--light-button-border-secondary)',
  },
  fltRadio: {
    checked: {
      background: {
        primary: 'var(--primary-60)',
        hover: 'var(--primary-70)',
      },
      backgroundImg: {
        primary: 'var(--light-fltRadio-checked-bg-image-primary)',
        secondary: 'var(--light-fltRadio-checked-bg-image-secondary)',
      },
      outline: {
        primary: 'var(--light-fltRadio-checked-outline-primary)',
      },
      boxShadow: { primary: 'var(--light-fltRadio-checked-box-primary)' },
    },
    unchecked: {
      background: {
        primary: 'var(--light-fltRadio-unchecked-bg-primary)',
        hover: 'var(--light-fltRadio-unchecked-bg-hover)',
      },
      backgroundImg: {
        primary: 'var(--light-fltRadio-unchecked-bg-image-primary)',
        secondary: 'var(--light-fltRadio-unchecked-bg-image-secondary)',
      },
      outline: {
        primary: 'var(--light-fltRadio-unchecked-outline-primary)',
      },

      boxShadow: {
        primary: 'var(--light-fltRadio-unchecked-box-primary)',
        secondary: ' var(--light-fltRadio-unchecked-box-secondary)',
      },
    },
  },
  fltButton: {
    text: {
      primary: 'var(--light-fltButton-text-primary)',
      secondary: 'var(--primary-60)',
      tertiary: 'var(--grey-70)',
      quaternary: 'var(--grey-50)',
      danger: 'var(--light-fltButton-text-danger)',
    },
    dropdown: {
      background: {
        primary: 'var(--absolute-white)',
        hover: {
          primary: 'var(--grey-0)',
        },
        opened: {
          primary: 'var(--grey-0)',
        },
        active: {
          primary: 'var(--absolute-white)',
        },
      },
      border: {
        primary: 'var(--grey-20)',
        hover: {
          primary: 'var(--grey-30)',
        },
        opened: {
          primary: 'var(--grey-30)',
        },
        active: {
          primary: 'var(--grey-20)',
        },
      },
    },
    background: {
      primary: 'var(--primary-60)',
      secondary: 'var(--absolute-white)',
      tertiary: 'var(--grey-0)',
      danger: 'var(--light-fltButton-bg-danger)',
      hover: {
        primary: 'var(--primary-50)',
        secondary: 'var(--light-fltButton-bg-hover-secondary)',
        danger: 'var(--light-fltButton-bg-hover-danger)',
      },
      active: {
        primary: 'var(--primary-70)',
      },
      disabled: {
        primary: '',
      },
    },
    border: {
      primary: 'var(--primary-60)',
      secondary: 'var(--grey-10)',
      danger: 'var(--light-fltButton-border-danger)',
    },
    boxShadow: {
      primary: 'var(--light-fltButton-box-primary)',
      hover: {
        primary: 'var(--light-fltButton-box-hover-primary)',
        secondary: 'var(--light-fltButton-box-hover-secondary)',
        danger: 'var(--light-fltButton-box-hover-danger)',
      },
    },
  },
  fltIconButton: {
    background: {
      primary: 'var(--absolute-white)',
      hover: {
        primary: 'var(--absolute-white)',
      },
      active: {
        primary: 'var(--absolute-white)',
      },
      disabled: {
        primary: '',
      },
    },
    border: {
      primary: 'var(--grey-20)',
      hover: {
        primary: 'var(--primary-20)',
      },
      active: {
        primary: 'var(--primary-40)',
      },
    },
  },
  fltInput: {
    text: {
      primary: 'var(--grey-80)',
      error: 'var(--error-50)',
      valid: 'var(--highlight-green)',
      hover: {
        primary: 'var(--grey-40)',
      },
      active: {
        primary: 'var(--grey-80)',
      },
    },
    background: {
      primary: 'var(--absolute-white)',
      hover: {
        primary: '',
      },
    },
    border: {
      primary: 'var(--grey-20)',
      error: 'var(--error-40)',
      hover: {
        primary: 'var(--primary-20)',
      },
      focus: {
        primary: 'var(--primary-40)',
      },
      active: {
        primary: 'var(--primary-40)',
      },
    },
    boxShadow: {
      primary: 'var(--light-fltInput-box-primary)',
    },
    messageBox: {
      background: {
        error: 'var(--light-flt-input-messageBox-bg-error)',
      },
    },
  },
  fltCheckbox: {
    checked: {
      border: {
        primary: 'var(--light-fltCheckbox-checked-border-primary)',
      },
      background: {
        primary: 'var(--primary-60)',
      },
      boxShadow: {
        primary: 'var(--light-fltCheckbox-checked-box-primary)',
        secondary: 'var(--light-fltCheckbox-checked-box-secondary)',
      },
    },
    unchecked: {
      border: {
        primary: 'var(--grey-30)',
        hover: {
          primary: 'var(--primary-50)',
        },
      },
      boxShadow: {
        primary: 'var(--light-fltCheckbox-unchecked-box-primary)',
        secondary: 'var(--light-fltCheckbox-unchecked-box-secondary)',
      },
    },
  },
  fltSwitch: {
    background: {
      primary: 'var(--absolute-white)',
      pressed: {
        primary: 'var(--primary-60)',
      },
    },
    boxShadow: {
      primary: 'var(--light-fltSwitch-box-primary)',
    },
    border: {
      primary: 'var(--grey-30)',
      hover: {
        primary: 'var(--grey-60)',
      },
      pressed: {
        primary: 'var(--primary-50)',
      },
    },
    handle: {
      background: {
        primary: 'var(--grey-30)',
        pressed: {
          primary: 'var(--absolute-white)',
        },
        hover: {
          primary: 'var(--grey-60)',
        },
      },
    },
  },
  fltModal: {
    text: {
      primary: 'var(--light-fltModal-text-primary)',
      secondary: 'var(--light-fltModal-text-primary)',
    },
    background: {
      primary: 'var(--light-fltModal-bg-primary)',
    },
    border: {
      primary: 'var(--light-fltModal-border-primary)',
    },
    boxShadow: {
      primary: 'var(--light-fltModal-box-primary)',
      secondary: 'var(--light-fltModal-box-secondary)',
    },
    backdrop: {
      primary: 'var(--light-fltModal-backdrop-primary)',
    },
  },
  fltSnackBar: {
    text: {
      primary: 'var(--light-fltSnackBar-text-primary)',
    },
    background: {
      primary: 'var(--light-fltSnackBar-bg-primary)',
    },
    boxShadow: {
      primary: 'var(--light-fltSnackBar-box-primary)',
      secondary: 'var(--light-fltSnackBar-box-secondary)',
    },
    warning: 'var(--light-fltSnackBar-warning)',
    success: 'var(--light-fltSnackBar-success)',
    info: 'var(--light-fltSnackBar-info)',
    danger: 'var(--light-fltSnackBar-danger)',
  },
  fltMenu: {
    menuItem: {
      text: {
        primary: 'var(--light-fltMenu-menuItem-text-primary)',
        secondary: 'var(--light-fltMenu-menuItem-text-secondary)',
        hover: {
          primary: 'var(--light-fltMenu-menuItem-text-hover-primary)',
        },
      },
      background: {
        primary: 'var(--absolute-white)',
        hover: {
          primary: 'var(--grey-10)',
        },
      },
      icon: {
        primary: 'var(--light-fltMenu-menuItem-icon-primary)',
        hover: {
          primary: 'var(--light-fltMenu-menuItem-icon-hover-primary',
        },
      },
    },
    background: {
      primary: 'var(--grey-0)',
    },
    border: {
      primary: 'var(--light-fltMenu-border-primary)',
    },
    boxShadow: {
      primary: 'var(--light-fltMenu-box-primary)',
    },
    chip: {
      text: {
        primary: 'var(--light-fltMenu-chip-text-primary)', //#E4E5E9 //#2F2F31
      },
      background: {
        primary: 'var(--light-fltMenu-chip-bg-primary)', //#2B2D34 //FFFFFF
        hover: {
          primary: 'string',
        },
      },
      border: {
        primary: 'string',
      },
    },
  },
  fltTable: {
    text: {
      primary: 'var(--light-fltTable-text-primary)',
      secondary: 'var(--light-fltTable-text-secondary)',
    },
    background: {
      primary: 'var(--light-fltTable-bg-primary)',
      secondary: 'var(--light-fltTable-bg-secondary)',
      hover: {
        primary: `var(--light-fltTable-hover-primary)`,
      },
    },
    border: {
      primary: 'var(--light-fltTable-border-primary)',
      secondary: 'var(--light-fltTable-border-secondary)',
      tertiary: 'var(--light-fltTable-border-tertiary)',
    },
    boxShadow: {
      primary: 'var(--light-fltTable-box-primary)',
    },
    select: {
      color: {
        primary: 'var(--light-fltTable-cell-select-text-primary)',
      },
      background: {
        primary: 'var(--light-fltTable-cell-select-bg-primary)',
      },
    },
    icon: {
      status: {
        active: 'var(--light-fltTable-icon-status-active)',
        inactive: 'var(--light-fltTable-icon-status-inactive)',
      },
      arrow: 'var(--light-fltTable-icon-arrow)',
    },
  },
  icon: {
    colorPrimary: 'var(--light-icon-color-primary)',
    colorSecondary: 'var(--light-icon-color-secondary)',
    danger: 'var(--light-icon-color-danger)',
  },
  input: {
    border: 'var(--light-flt-input-border)',
    bg: 'var(--light-flt-input-bg)',
    text: 'var(--light-flt-input-text)',
    error: 'var(--light-flt-input-error)',
    hover: 'var(--light-flt-input-hover)',
    focus: 'var(--light-flt-input-focus)',
    valid: 'var(--highlight-green)',
  },
  menu: {
    bg: 'var(--light-menu-bg)',
    borderPrimary: 'var(--light-menu-border-primary)',
    borderSecondary: 'var(--light-menu-border-secondary)',
    textPrimary: 'var(--light-menu-text-primary)',
    textSecondary: 'var(--light-menu-text-secondary)',
    icon: {
      colorPrimary: 'var(--light-menu-icon-color-primary)',
    },
    chip: {
      bg: 'var(--light-menu-chip-bg)',
      textPrimary: 'var(--light-menu-chip-text-primary)',
      textSecondary: 'var(--light-menu-chip-text-secondary)',
      border: 'var(--light-menu-chip-border)',
    },
  },
  welcome: {
    indicator: {
      bgPrimary: 'var(--light-welcome-indicator-bg-primary)',
      bgSecondary: 'var(--light-welcome-indicator-bg-secondary)',
    },
  },

  //New Theme Color
  surface: {
    background: {
      primary: 'var(--light-surface-background-primary)',
      secondary: 'var(--light-surface-background-secondary)',
      tertiary: 'var(--light-surface-background-tertiary)',
      success: 'var(--light-surface-background-success)',
      warning: 'var(--light-surface-background-warning)',
      error: 'var(--light-surface-background-error)',
      hover: 'var(--light-surface-background-hover)',
    },
    button: {
      primary: {
        active: 'var(--light-surface-button-primary-active)',
        default: 'var(--light-surface-button-primary-default)',
        disabled: 'var(--light-surface-button-primary-disabled)',
        hover: 'var(--light-surface-button-primary-hover)',
      },
      secondary: {
        active: 'var(--light-surface-button-secondary-active)',
        default: 'var(--light-surface-button-secondary-default)',
        disabled: 'var(--light-surface-button-secondary-disabled)',
        hover: 'var(--light-surface-button-secondary-hover)',
      },
      tertiary: {
        active: 'var(--light-surface-button-tertiary-active)',
        default: 'var(--light-surface-button-tertiary-default)',
        disabled: 'var(--light-surface-button-tertiary-disabled)',
        hover: 'var(--light-surface-button-tertiary-hover)',
      },
    },
    toggle: {
      active: 'var(--light-surface-toggle-active)',
      default: 'var(--light-surface-toggle-default)',
      inactive: 'var(--light-surface-toggle-inactive)',
      hover: 'var(--light-surface-toggle-hover)',
    },
    overlay: {
      primary: 'var(--light-surface-overlay-primary)',
      secondary: 'var(--light-surface-overlay-secondary)',
    },
  },

  content: {
    accentIcon: 'var(--light-content-accent-icon)',
    accentText: 'var(--light-content-accent-text)',
    error: 'var(--light-content-error)',
    primary: 'var(--light-content-primary)',
    secondary: 'var(--light-content-secondary)',
    static: 'var(--light-content-static)',
    success: 'var(--light-content-success)',
    tertiary: 'var(--light-content-tertiary)',
    warning: 'var(--light-content-warning)',
    accent: {
      blue: 'var(--light-content-accent-blue)'
    },

    button: {
      primary: {
        active: 'var(--light-content-button-primary-active)',
        default: 'var(--light-content-button-primary-default)',
        disabled: 'var(--light-content-button-primary-disabled)',
        hover: 'var(--light-content-button-primary-hover)',
      },
      secondary: {
        active: 'var(--light-content-button-secondary-active)',
        default: 'var(--light-content-button-secondary-default)',
        disabled: 'var(--light-content-button-secondary-disabled)',
        hover: 'var(--light-content-button-secondary-hover)',
      },
      tertiary: {
        active: 'var(--light-content-button-tertiary-active)',
        default: 'var(--light-content-button-tertiary-default)',
        disabled: 'var(--light-content-button-tertiary-disabled)',
        hover: 'var(--light-content-button-tertiary-hover)',
      },
    },
  },

  border: {
    inverse: 'var(--light-border-inverse)',
    accent: 'var(--light-border-accent)',
    error: 'var(--light-border-error)',
    primary: 'var(--light-border-primary)',
    secondary: 'var(--light-border-secondary)',
    tertiary: 'var(--light-border-tertiary)',
  },

  opacity: {
    gray00: 'var(--light-opacity-grey-00)',
    gray04: 'var(--light-opacity-grey-04)',
    gray08: 'var(--light-opacity-grey-08)',
    gray16: 'var(--light-opacity-grey-16)',
    gray20: 'var(--light-opacity-grey-20)',
    gray40: 'var(--light-opacity-grey-40)',
  },

  boxshadow: {
    small: 'var(--light-box-shadow-small)',
    medium: 'var(--light-box-shadow-medium)',
    focused: 'var(--light-box-shadow-focused)',
  },
};

export const darkTheme: Theme = {
  themeType: 'dark',
  color: {
    white: 'var(--absolute-white)',
    black: 'var(--black)',
    grey: 'var(--grey)',
  },
  pages: {
    text: {
      primary: 'var(--absolute-white)',
      secondary: 'var(--grey-10)',
      tertiary: 'var(--absolute-white)',
      quaternary: 'var(--grey-40)',
      quinary: 'var(--grey-40)',
      // senary: 'var(--primary-50)',
      // septenary: 'var(--absolute-white)',
    },
    background: {
      primary: 'var(--grey-90)',
    },
    avatar: { border: { primary: 'var(--dark-pages-avatar-border-primary)' } },
    border: {
      primary: 'var(--grey-80)',
      secondary: 'var(--grey-70)',
    },
    dropShadow: 'var(--dark-pages-dropShadow)',
    scrollbar: {
      thumb: 'var(--dark-scrollbar-thumb)',
      track: 'var(--dark-scrollbar-track)',
      thumbBorder: 'var(--dark-thumb-border)',
    },
    layout: {
      sidebar: {
        text: {
          primary: 'var(--absolute-white)',
          secondary: 'var(--grey-10)',
          tertiary: 'var(--grey-30)',
          quaternary: 'var(--grey-50)',
          quinary: 'var(--grey-40)',
          senary: 'var(--primary-50)',
          septenary: 'var(--grey-80)',
          hover: {
            primary: 'var(--grey-20)',
            secondary: 'var(--primary-50)',
          },
        },
        icon: {
          primary: 'var(--grey-40)',
          secondary: 'var(--grey-10)',
          tertiary: 'var(--primary-50)',
          quaternary: 'var(--grey-30)',
        },
        scrollBarColor: 'var(--light-pages-scroll-color)',
        border: {
          primary: 'var(--grey-80)',
          secondary: 'var(--grey-80)',
          tertiary: 'var(--grey-80)',
        },
        background: {
          primary: 'var(--grey-90)',
          hover: {
            primary: 'var(--grey-bg-dark)',
          },
        },
      },
      secondarySidebar: {
        text: {
          primary: 'var(--grey-10)',
          hover: {
            primary: 'var(--grey-10)',
          },
          active: {
            primary: 'var(--grey-20)',
          },
          focus: {
            primary: 'var(--grey-10)',
          },
        },
        background: {
          primary: 'var(--grey-bg-light)',
          hover: { primary: 'var(--dark-pages-bg-primary)' },
          active: { primary: '' },
        },
        boxShadow: {
          primary: `none`,
        },
        border: {
          primary: 'var(--grey-80)',
        },
      },
      header: {
        background: {
          primary: 'var(--grey-100)',
          secondary: 'var(--grey-bg-light)',
        },
        text: {
          primary: 'var(--absolute-white)',
          secondary: 'var(--grey-30)',
          hover: { secondary: 'var(--grey-0)' },
        },
        border: {
          primary: 'var(--grey-80)',
        },
        icon: {
          primary: 'var(--grey-30)',
          hover: { primary: 'var(--grey-0)' },
        },
      },
    },
  },

  textPrimary: 'var(--dark-text-primary)',
  textSecondary: 'var(--dark-text-secondary)',
  bg: 'var(--dark-bg)',
  borderPrimary: 'var(--dark-border-primary)',
  borderSecondary: 'var(--dark-border-secondary)',
  button: {
    textPrimary: 'var(--dark-button-text-primary)',
    textSecondary: 'var(--dark-button-text-secondary)',
    bgPrimary: 'var(--dark-button-bg-primary)',
    bgSecondary: 'var(--dark-button-bg-secondary)',
    borderPrimary: 'var(--dark-button-border-primary)',
    borderSecondary: 'var(--dark-button-border-secondary)',
  },
  fltRadio: {
    checked: {
      background: {
        primary: 'var(--dark-fltRadio-checked-bg-primary)',
        hover: 'var(--dark-fltRadio-checked-bg-hover)',
      },
      backgroundImg: {
        primary: 'var(--dark-fltRadio-checked-bg-image-primary)',
        secondary: 'var(--dark-fltRadio-checked-bg-image-secondary)',
      },
      outline: {
        primary: 'var(--dark-fltRadio-checked-outline-primary)',
      },
      boxShadow: { primary: 'var(--dark-fltRadio-checked-box-primary)' },
    },
    unchecked: {
      background: {
        primary: 'var(--dark-fltRadio-unchecked-bg-primary)',
        hover: 'var(--dark-fltRadio-unchecked-bg-hover)',
      },
      backgroundImg: {
        primary: 'var(--dark-fltRadio-unchecked-bg-image-primary)',
        secondary: 'var(--dark-fltRadio-unchecked-bg-image-secondary)',
      },
      outline: {
        primary: 'var(--dark-fltRadio-unchecked-outline-primary)',
      },

      boxShadow: {
        primary: 'var(--dark-fltRadio-checked-box-primary)',
        secondary: ' var(--dark-fltRadio-unchecked-box-secondary)',
      },
    },
  },
  fltButton: {
    text: {
      primary: 'var(--light-fltButton-text-primary)',
      secondary: 'var(--primary-60)',
      tertiary: 'var(--grey-80)',
      quaternary: 'var(--grey-50)',
      danger: 'var(--light-fltButton-text-danger)',
    },
    dropdown: {
      background: {
        primary: 'var(--grey-bg-light)',
        hover: {
          primary: 'var(--grey-80)',
        },
        opened: {
          primary: 'var(--grey-80)',
        },
        active: {
          primary: 'var(--grey-bg-light)',
        },
      },
      border: {
        primary: 'var(--grey-70)',
        hover: {
          primary: 'var(--grey-60)',
        },
        opened: {
          primary: 'var(--grey-70)',
        },
        active: {
          primary: 'var(--grey-50)',
        },
      },
    },
    background: {
      primary: 'var(--primary-60)',
      secondary: 'var(--absolute-white)',
      tertiary: 'var(--grey-0)',
      danger: 'var(--light-fltButton-bg-danger)',
      hover: {
        primary: 'var(--primary-50)',
        secondary: 'var(--light-fltButton-bg-hover-secondary)',
        danger: 'var(--light-fltButton-bg-hover-danger)',
      },
      active: {
        primary: 'var(--primary-70)',
      },
      disabled: {
        primary: '',
      },
    },
    border: {
      primary: 'var(--primary-60)',
      secondary: 'var(--primary-60)',
      danger: 'var(--light-fltButton-border-danger)',
    },
    boxShadow: {
      primary: 'var(--light-fltButton-box-primary)',
      hover: {
        primary: 'var(--light-fltButton-box-hover-primary)',
        secondary: 'var(--light-fltButton-box-hover-secondary)',
        danger: 'var(--light-fltButton-box-hover-danger)',
      },
    },
  },
  fltIconButton: {
    background: {
      primary: 'var(--grey-bg-dark)',
      hover: {
        primary: 'var(--grey-bg-dark)',
      },
      active: {
        primary: 'var(--grey-bg-dark)',
      },
      disabled: {
        primary: '',
      },
    },
    border: {
      primary: 'var(--grey-70)',
      hover: {
        primary: 'var(--grey-60)',
      },
      active: {
        primary: 'var(--grey-40)',
      },
    },
  },
  fltCheckbox: {
    checked: {
      border: {
        primary: 'var(--dark-fltCheckbox-checked-border-primary)',
      },
      background: {
        primary: 'var(--dark-fltCheckbox-checked-bg-primary)',
      },
      boxShadow: {
        primary: 'var(--dark-fltCheckbox-checked-box-primary)',
        secondary: 'var(--dark-fltCheckbox-checked-box-secondary)',
      },
    },
    unchecked: {
      border: {
        primary: 'var(--grey-30)',
        hover: {
          primary: 'var(--grey-10)',
        },
      },
      boxShadow: {
        primary: 'var(--dark-fltCheckbox-unchecked-box-primary)',
        secondary: 'var(--dark-fltCheckbox-unchecked-box-secondary)',
      },
    },
  },
  fltSwitch: {
    background: {
      primary: 'var(--grey-70)',
      pressed: {
        primary: 'var(--primary-60)',
      },
    },
    boxShadow: {
      primary: 'var(--dark-fltSwitch-box-primary)',
    },
    handle: {
      background: {
        primary: 'var(--grey-40)',
        pressed: {
          primary: 'var(--absolute-white)',
        },
        hover: {
          primary: 'var(--grey-20)',
        },
      },
    },
    border: {
      primary: 'var(--grey-60)',
      hover: {
        primary: 'var(--grey-40)',
      },
      pressed: {
        primary: 'var(--primary-50)',
      },
    },
  },
  fltInput: {
    text: {
      primary: 'var(--grey-10)',
      error: 'var(--error-50)',
      valid: 'var(--highlight-green)',
      hover: {
        primary: 'var(--grey-70)',
      },
      active: {
        primary: 'var(--grey-0)',
      },
    },
    background: {
      primary: 'var(--grey-bg-light)',
      hover: {
        primary: '',
      },
    },
    border: {
      primary: 'var(--grey-80)',
      error: 'var(--error-40)',
      hover: {
        primary: 'var(--grey-60)',
      },
      focus: {
        primary: 'var(--grey-60)',
      },
      active: {
        primary: 'var(--grey-70)',
      },
    },
    boxShadow: {
      primary: 'var(--light-fltInput-box-primary)',
    },
    messageBox: {
      background: {
        error: 'var(--light-flt-input-messageBox-bg-error)',
      },
    },
  },
  fltSnackBar: {
    text: {
      primary: 'var(--dark-fltSnackBar-text-primary)',
    },
    background: {
      primary: 'var(--dark-fltSnackBar-bg-primary)',
    },
    boxShadow: {
      primary: 'var(--dark-fltSnackBar-box-primary)',
      secondary: 'var(--dark-fltSnackBar-box-secondary)',
    },
    warning: 'var(--dark-fltSnackBar-warning)',
    success: 'var(--dark-fltSnackBar-success)',
    info: 'var(--dark-fltSnackBar-info)',
    danger: 'var(--dark-fltSnackBar-danger)',
  },
  fltModal: {
    text: {
      primary: 'var(--dark-fltModal-text-primary)',
      secondary: 'var(--dark-fltModal-text-primary)',
    },
    background: {
      primary: 'var(--dark-fltModal-bg-primary)',
    },
    border: {
      primary: 'var(--dark-fltModal-border-primary)',
    },
    backdrop: {
      primary: 'var(--dark-fltModal-backdrop-primary)',
    },
    boxShadow: {
      primary: 'var(--dark-fltModal-box-primary)',
      secondary: 'var(--dark-fltModal-box-secondary)',
    },
  },
  fltTable: {
    text: {
      primary: 'var(--dark-fltTable-text-primary)',
      secondary: 'var(--dark-fltTable-text-secondary)',
    },
    background: {
      primary: 'var(--dark-fltTable-bg-primary)',
      secondary: 'var(--dark-fltTable-bg-secondary)',
      hover: {
        primary: `var(--dark-fltTable-hover-primary)`,
      },
    },
    border: {
      primary: 'var(--dark-fltTable-border-primary)',
      secondary: 'var(--dark-fltTable-border-secondary)',
      tertiary: 'var(--dark-fltTable-border-tertiary)',
    },
    boxShadow: {
      primary: 'var(--dark-fltTable-box-primary)',
    },
    select: {
      color: {
        primary: 'var(--dark-fltTable-cell-select-text-primary)',
      },
      background: {
        primary: 'var(--dark-fltTable-cell-select-bg-primary)',
      },
    },
    icon: {
      status: {
        active: 'var(--dark-fltTable-icon-status-active)',
        inactive: 'var(--dark-fltTable-icon-status-inactive)',
      },
      arrow: 'var(--dark-fltTable-icon-arrow)',
    },
  },
  fltMenu: {
    menuItem: {
      text: {
        primary: 'var(--dark-fltMenu-menuItem-text-primary)',
        secondary: 'var(--dark-fltMenu-menuItem-text-secondary)',
        hover: {
          primary: 'var(--dark-fltMenu-menuItem-text-hover-primary)',
        },
      },
      background: {
        primary: 'var(--grey-bg-light)',
        hover: {
          primary: 'var(--grey-70)',
        },
      },
      icon: {
        primary: 'var(--dark-fltMenu-menuItem-icon-primary)',
        hover: {
          primary: 'var(--dark-fltMenu-menuItem-icon-hover-primary',
        },
      },
    },
    background: {
      primary: 'var(--grey-bg-light)',
    },
    border: {
      primary: 'var(--dark-fltMenu-border-primary)',
    },
    boxShadow: {
      primary: 'var(--dark-fltMenu-box-primary)',
    },
    chip: {
      text: {
        primary: 'var(--dark-fltMenu-chip-text-primary)', //#E4E5E9 //#2F2F31
      },
      background: {
        primary: 'var(--dark-fltMenu-chip-bg-primary)', //#2B2D34 //FFFFFF
        hover: {
          primary: 'string',
        },
      },
      border: {
        primary: 'string',
      },
    },
  },
  icon: {
    colorPrimary: 'var(--dark-icon-color-primary)',
    colorSecondary: 'var(--dark-icon-color-secondary)',
    danger: 'var(--dark-icon-color-danger)',
  },
  input: {
    border: 'var(--dark-flt-input-border)',
    bg: 'var(--dark-flt-input-bg)',
    text: 'var(--dark-flt-input-text)',
    hover: 'var(--dark-flt-input-hover)',
    focus: 'var(--dark-flt-input-focus)',
    valid: 'var(--highlight-green',
    error: 'var(--dark-flt-input-error)',
  },
  menu: {
    bg: 'var(--dark-menu-bg)',
    borderPrimary: 'var(--dark-menu-border-primary)',
    borderSecondary: 'var(--dark-menu-border-secondary)',
    textPrimary: 'var(--dark-menu-text-primary)',
    textSecondary: 'var(--dark-menu-text-secondary)',
    icon: {
      colorPrimary: 'var(--dark-menu-icon-color-primary)',
    },
    chip: {
      bg: 'var(--dark-menu-chip-bg)',
      textPrimary: 'var(--dark-menu-chip-text-primary)',
      textSecondary: 'var(--dark-menu-chip-text-secondary)',
      border: 'var(--dark-menu-chip-border)',
    },
  },
  welcome: {
    indicator: {
      bgPrimary: 'var(--dark-welcome-indicator-bg-primary)',
      bgSecondary: 'var(--dark-welcome-indicator-bg-secondary)',
    },
  },
  surface: {
    background: {
      primary: 'var(--dark-surface-background-primary)',
      secondary: 'var(--dark-surface-background-secondary)',
      tertiary: 'var(--dark-surface-background-tertiary)',
      success: 'var(--dark-surface-background-success)',
      warning: 'var(--dark-surface-background-warning)',
      error: 'var(--dark-surface-background-error)',
      hover: 'var(--dark-surface-background-hover)',
    },
    button: {
      primary: {
        active: 'var(--dark-surface-button-primary-active)',
        default: 'var(--dark-surface-button-primary-default)',
        disabled: 'var(--dark-surface-button-primary-disabled)',
        hover: 'var(--dark-surface-button-primary-hover)',
      },
      secondary: {
        active: 'var(--dark-surface-button-secondary-active)',
        default: 'var(--dark-surface-button-secondary-default)',
        disabled: 'var(--dark-surface-button-secondary-disabled)',
        hover: 'var(--dark-surface-button-secondary-hover)',
      },
      tertiary: {
        active: 'var(--dark-surface-button-tertiary-active)',
        default: 'var(--dark-surface-button-tertiary-default)',
        disabled: 'var(--dark-surface-button-tertiary-disabled)',
        hover: 'var(--dark-surface-button-tertiary-hover)',
      },
    },
    toggle: {
      active: 'var(--dark-surface-toggle-active)',
      default: 'var(--dark-surface-toggle-default)',
      inactive: 'var(--dark-surface-toggle-inactive)',
      hover: 'var(--dark-surface-toggle-hover)',
    },
    overlay: {
      primary: 'var(--dark-surface-overlay-primary)',
      secondary: 'var(--dark-surface-overlay-secondary)',
    }
  },
  content: {
    accentIcon: 'var(--dark-content-accent-icon)',
    accentText: 'var(--dark-content-accent-text)',
    error: 'var(--dark-content-error)',
    primary: 'var(--dark-content-primary)',
    secondary: 'var(--dark-content-secondary)',
    static: 'var(--dark-content-static)',
    success: 'var(--dark-content-success)',
    tertiary: 'var(--dark-content-tertiary)',
    warning: 'var(--dark-content-warning)',
    accent: {
      blue: 'var(--dark-content-accent-blue)'
    },

    button: {
      primary: {
        active: 'var(--dark-content-button-primary-active)',
        default: 'var(--dark-content-button-primary-default)',
        disabled: 'var(--dark-content-button-primary-disabled)',
        hover: 'var(--dark-content-button-primary-hover)',
      },
      secondary: {
        active: 'var(--dark-content-button-secondary-active)',
        default: 'var(--dark-content-button-secondary-default)',
        disabled: 'var(--dark-content-button-secondary-disabled)',
        hover: 'var(--dark-content-button-secondary-hover)',
      },
      tertiary: {
        active: 'var(--dark-content-button-tertiary-active)',
        default: 'var(--dark-content-button-tertiary-default)',
        disabled: 'var(--dark-content-button-tertiary-disabled)',
        hover: 'var(--dark-content-button-tertiary-hover)',
      },
    },
  },
  border: {
    inverse: 'var(--dark-border-inverse)',
    accent: 'var(--dark-border-accent)',
    error: 'var(--dark-border-error)',
    primary: 'var(--dark-border-primary)',
    secondary: 'var(--dark-border-secondary)',
    tertiary: 'var(--dark-border-tertiary)',
  },
  opacity: {
    gray00: 'var(--dark-opacity-grey-00)',
    gray04: 'var(--dark-opacity-grey-04)',
    gray08: 'var(--dark-opacity-grey-08)',
    gray16: 'var(--dark-opacity-grey-16)',
    gray20: 'var(--dark-opacity-grey-20)',
    gray40: 'var(--dark-opacity-grey-40)',
  },
  boxshadow: {
    small: 'var(--dark-box-shadow-small)',
    medium: 'var(--dark-box-shadow-medium)',
    focused: 'var(--dark-box-shadow-focused)',
  },
};
