import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductTaxes } from '@src/dbSyncManager/tables/productTax.entity';

interface ProductTaxesState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: ProductTaxesState = {
  mapData: new Map(),
  arrayData: [],
};

const productTaxesSlice = createSlice({
  name: 'productTaxes',
  initialState,
  reducers: {
    setProductTaxes(state, action: PayloadAction<ProductTaxes[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setProductTaxes } = productTaxesSlice.actions;
export default productTaxesSlice.reducer;