import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import { useSelector } from 'react-redux';

export const FltTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: useSelector((state: AppState) => state.theme.theme.boxshadow.medium),
    backgroundColor: useSelector((state: AppState) => state.theme.theme.surface.overlay.primary),

    borderRadius: 6,
    borderColor: useSelector((state: AppState) => state.theme.theme.border.tertiary),
    fontSize: 11,
    padding: 0,
  },
  [`& .MuiTooltip-arrow`]: {
    color: useSelector((state: AppState) => state.theme.theme.surface.overlay.primary),
    borderColor: useSelector((state: AppState) => state.theme.theme.border.tertiary),
    boxShadow: useSelector((state: AppState) => state.theme.theme.boxshadow.medium),
  },
}));
