import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentAddress } from '@src/dbSyncManager/tables/documentAddress.entity';

interface DocumentAddressState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: DocumentAddressState = {
  mapData: new Map(),
  arrayData: [],
};

const documentAddressSlice = createSlice({
  name: 'documentAddress',
  initialState,
  reducers: {
    setDocumentAddress(state, action: PayloadAction<DocumentAddress[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setDocumentAddress } = documentAddressSlice.actions;
export default documentAddressSlice.reducer;