import Axios from 'axios';

import { HandleAlertClick } from '@src/components/base/fltSnackBar/fltSnackBar';
import { checkTokenExpiry } from '@src/utils/common';
import { API_URL } from '../config';

const authUserString = window.localStorage?.getItem('appState') || '';
const accessToken = JSON.parse(authUserString || '{}')?.auth?.accessToken || '';

export const axios = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  },
});

axios.interceptors.request.use(
  (config: any) => {
    const excludedRoutes = [
      '/signup',
      '/login',
      '/signup/accountsetup',
      '/signup/accountloginprofile',
      '/signup/userloginprofile',
      '/invites',
      '/teams',
    ]; // List of routes that don't require authentication
    const url = config.url;

    console.log('url', url);
    // Skip token authentication for excluded routes
    if (excludedRoutes.some((route) => url?.includes(route))) {
      return config; // Proceed without token check
    }

    // Perform token validation for all other routes
    if (!checkTokenExpiry()) {
      // console.error('Token expired. Redirecting to login.');
      // Handle token expiration (e.g., redirect to login or refresh token)
      // Optionally throw an error to halt the request
    }

    return config; // Proceed with the request
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: any) => {
    // Always return the response if there are no issues
    return response;
  },
  (error: any) => {
    // Handle errors
    return Promise.reject(error);
  }
);

// // Generic API call handler
// export const apiCall = async <T>(
//   method: 'get' | 'post' | 'put' | 'delete',
//   url: string,
//   data?: any,
//   handleAlertClick?: HandleAlertClick,
// ): Promise<{ data: any; status: number }> => {
//   try {
//     const response = await axios.request<T>({
//       method,
//       url,
//       data,
//     });
//     return { data: response.data, status: response.status };
//   } catch (error: any) {
//     // Handle errors
//     console.error('API Error:', error);
//     if (error.response) {
//       // throw new Error('Server error occurred');
//       if (handleAlertClick) {
//         handleAlertClick('Server error occurred', 'error');
//       }
//       throw new Error('Server error occurred');
//     } else if (error.request) {
//       // throw new Error('Network error occurred');
//       if (handleAlertClick) {
//         handleAlertClick('Server error occurred', 'error');
//       }
//       throw new Error('Network error occurred');
//     } else {
//       // throw new Error('An unexpected error occurred');
//       if (handleAlertClick) {
//         handleAlertClick('Server error occurred', 'error');
//       }
//       throw new Error('An unexpected error occurred');
//     }
//   }
// };

export const apiCall = async <T>(
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  data?: any,
  handleAlertClick?: HandleAlertClick
): Promise<{ data: any; status: number }> => {
  try {
    const response = await axios.request<T>({
      method,
      url,
      data,
    });
    return { data: response.data, status: response.status };
  } catch (error: any) {
    console.error('API Error:', error);

    if (error.response) {
      const status = error.response.status;

      const responseMessage = error.response.data?.message; // Assuming the error message is in the 'message' field
      let errorMessage: string;

      switch (status) {
        case 400:
        case 401:
        case 404:
          errorMessage = responseMessage;
          break;
       
        default:
          errorMessage = responseMessage || 'An error occurred. Please try again later.';
      }

      if (handleAlertClick) {
        handleAlertClick(errorMessage, 'error');
      }

      throw new Error(errorMessage);
    } else if (error.request) {
      const networkErrorMessage = 'Network Error: Unable to reach the server.';
      if (handleAlertClick) {
        handleAlertClick(networkErrorMessage, 'error');
      }
      throw new Error(networkErrorMessage);
    } else {
      const unexpectedErrorMessage = 'An unexpected error occurred.';
      if (handleAlertClick) {
        handleAlertClick(unexpectedErrorMessage, 'error');
      }
      throw new Error(unexpectedErrorMessage);
    }
  }
};

//http://localhost:5001
//https://notification.theflute.app
export const notificationAxios = Axios.create({
  baseURL: 'http://localhost:5001',
  headers: {
    'Content-Type': 'application/json',
  },
});

notificationAxios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
