import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '@src/dbSyncManager/tables/product.entity';

interface ProductsState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: ProductsState = {
  mapData: new Map(),
  arrayData: [],
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<Product[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setProducts } = productsSlice.actions;
export default productsSlice.reducer;