import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendSignupEmail } from './actions/signupAction';

const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    email: '',
    password: '',
    loading: false,
    error: null as string | null, // Include loading and error states
  },
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendSignupEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendSignupEmail.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(sendSignupEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setEmail, setPassword } = signupSlice.actions;
export default signupSlice.reducer;
