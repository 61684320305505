import { AppState } from '@src/app/rootReducer';
import React from 'react';
import { useSelector } from 'react-redux';
import FltIcon from '../../fltIcon/fltIcon';
import { FltButtonDropdownProps, FltButtonProps } from './fltButtonInterface';
import { FltButtonWrapper } from './fltButtonStyles';

const FltButton = React.forwardRef<HTMLButtonElement | HTMLDivElement, FltButtonProps>(
  (
    {
      size = 'default',
      rightGlyph,
      leftGlyph,
      type,
      isLoading,
      action,
      disabled,
      children,
      onClick,
      // appearance = 'filled',
      shape,
      childLayout = 'default',
      btnFor = 'normal-btn',
      width,
      styles,
      ...restProps
    },
    ref // ref will now be forwarded to this component
  ) => {
    const theme = useSelector((data: AppState) => data.theme.theme);

    // Determine the additional className based on isDropdownOpen
    const dropdownClass =
      btnFor === 'dropdown-btn' && (restProps as FltButtonDropdownProps).isDropdownOpen
        ? 'dropdown-open'
        : '';
    const iconName =
      btnFor === 'dropdown-btn' && (restProps as FltButtonDropdownProps).isDropdownOpen
        ? 'CaretUp'
        : 'CaretDown';

    return (
      <FltButtonWrapper
        type={action}
        size={size}
        btnType={type}
        styles={styles}
        onClick={onClick}
        disabled={disabled}
        theme={theme}
        shape={shape}
        childLayout={childLayout}
        className={dropdownClass}
        btnFor={btnFor}
        {...restProps}
        width={width}
        ref={ref as any} // Forward ref here
      >
        {(leftGlyph || children || rightGlyph || isLoading) && (
          <div className="btn-content">
            <div className="btn-left-content">
              {leftGlyph}
              {children && children}
            </div>
            {btnFor === 'dropdown-btn' ? (
              <FltIcon weight="thin" name={iconName} size={18} />
            ) : (
              rightGlyph
            )}
            {isLoading && <>loading...</>}
          </div>
        )}
      </FltButtonWrapper>
    );
  }
);

export default FltButton;
