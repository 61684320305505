// src/redux/actions/authActions.ts

import { AppDispatch } from '@src/app/store';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '@src/lib/axios';
import { setPasswordFailure, setPasswordRequest, setPasswordSuccess } from '../signupApiSlice';

export const sendSignupEmail = createAsyncThunk(
  'signup/sendSignupEmail', // Action name
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await axios.post('v1/signup/progressiveuserloginprofile', {
        publicCredential: email,
      });
      return response.data; // Payload for the fulfilled action
    } catch (error: any) {
      // Payload for the rejected action
      return rejectWithValue(error.response?.data?.message || 'Something went wrong');
    }
  }
);
export const handleSignupApi: any =
  (newPayload: { publicCredential: string; privateCredentail: string }) =>
  async (dispatch: AppDispatch) => {
    dispatch(setPasswordRequest());
    try {
      const response = await axios.post('v1/signup/userloginprofile', newPayload);

      dispatch(setPasswordSuccess(response.data));
      return response.data;
    } catch (error: any) {
      dispatch(setPasswordFailure(error.response?.data?.message || 'Failed to set password'));
    }
  };

export const handleIntroductionApi: any =
  (newPayload: { firstName: string; lastName: string; preferredName?: string }) =>
  async (dispatch: AppDispatch) => {
    try {
      const accessToken = sessionStorage.getItem('signupAccessToken') || '';
      console.log('sssss', newPayload);
      const { userLoginProfileId } = JSON.parse(atob(accessToken.split('.')[1]));
      console.log('decoded', userLoginProfileId);
      const response = await axios.put(
        `v1/signup/userloginprofile/${userLoginProfileId}`,
        newPayload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log('error', error);
    }
  };
export const handleWorkspaceApi: any = (newPayload: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post('v1/signup/accountloginprofile', newPayload);
    return response.data;
  } catch (error: any) {}
};

export const handleTeamCreateApi: any =
  (newPayload: { name: string; description: string,icon?:string ,color?:string}) => async (dispatch: AppDispatch) => {
    try {
      const accessToken = sessionStorage.getItem('signupAccessToken') || '';
      const response = await axios.post('v1/teams', newPayload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error: any) {}
  };

export const inviteMembersAPI: any =
  (newPayload: { referredPublicCredential: string[] }) => async (dispatch: AppDispatch) => {
    try {
      const accessToken = sessionStorage.getItem('signupAccessToken') || '';
      const teamId = sessionStorage.getItem('teamId') || '';
      const response = await axios.post(`v1/teams/${teamId}/members/invite`, newPayload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error: any) {}
  };
export const inviteAPI: any =
  (newPayload: { referredPublicCredential: string[]; forAccountId: number }) =>
  async (dispatch: AppDispatch) => {
    try {
      const accessToken = sessionStorage.getItem('signupAccessToken') || '';

      const response = await axios.post(`v1/invites`, newPayload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error: any) {}
  };

export const verifyOtp = createAsyncThunk(
  'signup/otpVerify', // Action name
  async (payload: { publicCredential: string | null; otp: string }, { rejectWithValue }) => {
    try {
      // Destructure payload properties and send them in the request body
      const { publicCredential, otp } = payload;
      const response = await axios.post('v1/signup/progressiveuserloginprofile/verifyotp', {
        publicCredential,
        otp,
      });
      return response.data; // Payload for the fulfilled action
    } catch (error: any) {
      // Payload for the rejected action
      return rejectWithValue(error.response?.data?.message || 'Something went wrong');
    }
  }
);
