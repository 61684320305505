import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tax } from '@src/dbSyncManager/tables/tax.entity';

interface TaxesState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: TaxesState = {
  mapData: new Map(),
  arrayData: [],
};

const taxesSlice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    setTaxes(state, action: PayloadAction<Tax[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setTaxes } = taxesSlice.actions;
export default taxesSlice.reducer;
