import { lazyImport } from '@src/utils/lazyImport';
import { Navigate } from 'react-router-dom';

const { Login } = lazyImport(() => import('../pages/loginSignup'), 'Login');
// const { Logins } = lazyImport(() => import('../features/LoginSignup'), 'Logins');
const { SignUp } = lazyImport(() => import('../pages/loginSignup'), 'SignUp');
const { OtpVerification } = lazyImport(() => import('../pages/loginSignup'), 'OtpVerification');
const { PasswordSetup } = lazyImport(() => import('../pages/loginSignup'), 'PasswordSetup');
const { AccountSetup } = lazyImport(() => import('../pages/loginSignup'), 'AccountSetup');

const { Welcome } = lazyImport(() => import('../pages/loginSignup'), 'Welcome');
const { AcceptInvitation } = lazyImport(() => import('../pages/loginSignup'), 'AcceptInvitation');

export const publicRoutes = [
  // {
  //   path: '*',
  //   element: <Login />,
  // },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  { path: '/signup/otp', element: <OtpVerification /> },
  { path: '/signup/password', element: <PasswordSetup /> },
  { path: '/signup/accountsetup', element: <AccountSetup /> },

  {
    path: '/welcome',
    element: <Welcome />,
  },
  {
    path: '/invite/:id/accept',
    element: <AcceptInvitation />,
  },

  {
    // Catch-all route for unknown routes, redirecting to /login
    path: '*',
    element: <Navigate to="/login" replace />,
  },
];
