import Menu from '@mui/material/Menu';
import { AppState } from '@src/app/rootReducer';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText from '@src/components/base/fltText/fltText';
import CustomAvatar from '@src/components/composite/avatar/avatar';
import { db } from '@src/dbSyncManager/init';
import { logOut } from '@src/features/auth/authSlice';
import { toggleColorMode } from '@src/features/theme/themeSlice';
import { getDisplayName } from '@src/utils/common';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProfileMenuWrapper } from './profileMenuStyle';
type AuthType = {
  auth: {
    userId: number;
  };
};
export const ProfileMenu: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let theme = useSelector((state: AppState) => state.theme.theme);

  let userId = useSelector((state: AppState) => state.auth.userId);
  const user = useLiveQuery(async () => {
    let userList = await db.users.toArray();
    const user = userList.find(
      (data: object) => (data as { id: string | undefined }).id === userId
    );
    return user;
  });
  const handleToggleColorMode = () => {
    dispatch(toggleColorMode());
  };
  useEffect(() => {
    if (theme.themeType === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme.themeType]);

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const handleLogout = () => {
    dispatch(logOut());
    
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const displayName = getDisplayName(
    useSelector((state: AppState) => state?.displayName?.displayName)
  );
  const handleMenuButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span onClick={handleMenuButtonClick}>
        <CustomAvatar
          avatarColor={user?.avatarColor}
          avatarInitials={user?.avatarInitials}
          preferredName={user?.preferredName}
          firstName={user?.firstName}
          lastName={user?.lastName}
          // width="30px"
          // height="30px"
          // fontSize="12px"
          width="24px"
          height="24px"
          fontSize="10px"
        />
      </span>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1,
            width: '240px',
            backgroundColor: theme.surface.background.secondary,
            border: `1px solid ${theme.border.secondary}`,
            boxShadow: theme.boxshadow.small,
          },
        }}
        MenuListProps={{
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ProfileMenuWrapper theme={theme}>
          <div className="avator-container">
            <CustomAvatar
              avatarColor={user?.avatarColor}
              avatarInitials={user?.avatarInitials}
              width="28px"
              height="28px"
              fontSize="12px"
            />
            <div className="avator-right-container">
              <FltText
                variant="heading-small"
                textColor="content-primary"
                styles={{ width: '180px' }}
                isEllipsis={true}
              >
                {displayName}
              </FltText>
              {user && user.publicCredential && (
                <FltText variant="body-small" textColor="content-tertiary">
                  {user.publicCredential}
                </FltText>
              )}
            </div>
          </div>
          <div className="menu-option-container">
            <div
              className="menu-item-container"
              onClick={() => navigate('/settings/my-account/preferences')}
            >
              <FltIcon name="FadersHorizontal" size={16} />
              <FltText variant="heading-medium" textColor="content-secondary">
                Preferences
              </FltText>
            </div>
            <div
              className="menu-item-container"
              onClick={() => navigate('/settings/my-account/profile')}
            >
              <FltIcon name="GearSix" size={16} />
              <FltText variant="heading-medium" textColor="content-secondary">
                Settings
              </FltText>
            </div>
            <div className="menu-item-container">
              <FltIcon name="Question" size={16} />
              <FltText variant="heading-medium" textColor="content-secondary">
                Help
              </FltText>
            </div>
          </div>
          <div className="menu-option-container">
            <div className="menu-item-container" onClick={() => handleLogout()}>
              <FltIcon name="SignOut" size={16} />
              <FltText variant="heading-medium" textColor="content-secondary">
                Logout
              </FltText>
            </div>
          </div>
        </ProfileMenuWrapper>
      </Menu>
    </div>
  );
};
