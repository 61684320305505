import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const sessionData = sessionStorage.getItem('introductionData');
const introductionData =sessionData ? JSON.parse(sessionData) : ''; 
const introductionSlice = createSlice({
  name: 'introduction',
  initialState: {
    firstname: introductionData?.firstname || '',
    lastname: introductionData?.lastname || '',
    initials: introductionData?.initials || '',
  },
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstname = action.payload;
    },
    setLastname: (state, action) => {
      state.lastname = action.payload;
    },
    setInitials: (state, action) => {
      state.initials = action.payload;
    },
  },
});

export const { setFirstName, setLastname, setInitials } = introductionSlice.actions;
export default introductionSlice.reducer;
