import { createSlice } from '@reduxjs/toolkit';
import { verifyOtp } from './actions/signupAction';

interface OtpState {
  otpVerified: boolean;
  otpError: any;
  loading: boolean;
}

const initialState: OtpState = {
  otpVerified: false,
  otpError: null,
  loading: false, // To manage loading state
};

const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle OTP verification request (Pending state)
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true; // Set loading state to true when request starts
        state.otpError = null; // Clear any existing errors
      })
      // Handle successful OTP verification (Fulfilled state)
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false; // Set loading state to false after the request completes
        state.otpVerified = true; // Set OTP as verified
        state.otpError = null; // Clear error on success
      })
      // Handle failed OTP verification (Rejected state)
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false; // Set loading state to false after the request completes
        state.otpVerified = false; // Set OTP as not verified
        state.otpError = action.payload || 'Something went wrong'; // Use the error message from the payload or default message
      });
  },
});

export default otpSlice.reducer;
